import React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/layout/seo"
import styled from "styled-components"
import { Link } from "gatsby"
import { keyframes } from "styled-components"

function About() {
  return (
    <Layout>
      <SEO title="About Me" />
      <Wrapper>
        <CloseBtn>
          <Link to="/">
            <img src="images/icons/cancel.png" alt="cancel"></img>
          </Link>
        </CloseBtn>
        <ScrollWrapper>
          <ContentWrapper>
            {" "}
            <img src="/images/about/AboutMe.png" alt="About" />
          </ContentWrapper>
        </ScrollWrapper>
        <Bg1>
          <ContentBodyWrapper>
            <Header>My Journey</Header>

            <Body>
              Married to a wife who is also a UX designer, the father of two
              children, a Seahawks fan, and tries to follow what Jesus said in
              the bible.
            </Body>

            <Body>
              I value <span>humans the most.</span> Whether it is customers or
              colleagues, we all have stories, and I would love to learn from
              you and build meaningful things together to make the world a
              better place.
            </Body>

            <Body>
              Email:{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="mailto: kennyhong@outlook.com"
              >
                kennyhong@outlook.com
              </a>{" "}
              <br />
              Linkedin:{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.linkedin.com/in/kenehong/"
              >
                www.linkedin.com/in/kenehong
              </a>
            </Body>
          </ContentBodyWrapper>
        </Bg1>
      </Wrapper>
    </Layout>
  )
}

export default About

const animation = keyframes`
from {opacity: 0; transform: translateY(10px);};
to {opacity: 1; transform: translateY(0px);};
`

const CloseBtn = styled.div`
  display: block;
  position: fixed;
  margin: 35px 30px;

  :hover {
    opacity: 0.4;
  }

  @media (max-width: 944px) {
    display: none;
  }

  img {
    width: 50px;
    height: 50px;
    opacity: 0.7;
  }
`

const Wrapper = styled.div`
  width: 100%;
  animation: ${animation} 1s 0.2s forwards;
  opacity: 0;
`

const ScrollWrapper = styled.div`
  overflow: auto;
  white-space: nowrap;
`

const ContentWrapper = styled.div`
  height: 465px;
  background-size: cover;
  display: inline-block;

  @media (max-width: 1500px) {
    height: 300px;
  }

  img {
    @media (max-width: 1500px) {
      height: 300px;
    }
  }
`

const ContentBodyWrapper = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
`

const Bg1 = styled.div`
  width: 100%;
  padding: 86px 200px;

  @media (max-width: 944px) {
    padding: 24px;
  }
`

const Header = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 130%;
  /* or 31px */
  color: #000000;
  margin-bottom: 24px;
`

const Body = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 150%;
  /* or 23px */

  /* Text */

  color: #000000;
  margin-bottom: 24px;

  a {
    :hover {
      text-decoration: underline;
    }
  }

  span {
    font-weight: bold;
  }
`
